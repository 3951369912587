<script setup>
import {ulid} from 'ulid';

const blocks = defineModel({
    type: Array,
    required: true,
});

const addBlock = () => {
    blocks.value.push({
        id: ulid(),
        after: blocks.value.length > 0 ? blocks.value[blocks.value.length - 1].after + 1 : 0,
        steps: [],
    });
};
</script>

<template>
  <div class="journey-block__add">
    <button class="btn btn--primary" @click="addBlock">
      <svg focusable="false" aria-hidden="true" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor">
        <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
      </svg>
      Add block
    </button>
  </div>
</template>
